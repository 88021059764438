<template>
  <body style="position: relative;">
  <div id="wrap">
		<section>
			<headerLayout v-bind:activeNum="active" v-on:updateActiveNum="updateTab"></headerLayout>
		</section>
    <main>
        <section class="sub-visual">
            <div class="innWrap">
                <div class="txt">
                    <h2>Flexline</h2>
                    <p>고객 사용 환경에 맞는 네트워크 최적화 및 대역폭 변경이 가능한 KT만의 기업용 인터넷 서비스</p>
                </div>
                <div class="location">
                    <ul>
                        <li><a href="#">PRODUCTS</a></li>
                        <li><a href="#">Flexline</a></li>
                        <li><span>서비스소개</span></li>
                    </ul>
                </div>
            </div>
        </section>
        <section class="sub-section svcInfo-flex bgGray">
            <dl class="innWrap flex sLayout01">
                <dt>
                    <h3 class="sLtitle">서비스 소개</h3>
                    <p>
                        최적의 네트워크 구성이 가능한 SD-WAN 솔루션, 고객 선택 중심의<br />
                        유연한 회선, 서비스 변경/현황 확인이 가능한 온라인 포탈, KT 운용<br />
                        센터를 통한 관리/관제 지원, Cloud 사업자와의 간편한 보안 연결을<br />
                        하나의 통합 서비스로 제공
                    </p>
                    <a @click="$router.push('/goSupCns000')" class="btn-md arrow btn-primary bdrds50 mgr30">컨설팅 신청</a>
                    <a @click="$router.push('/goSvcOrd000')" class="btn-md arrow btn-line02 bdrds50">온라인 청약</a>
                </dt>
                <dd>
                    <div class="imgWrap">
                        <div class="img"></div>
                    </div>
                </dd>
            </dl>
        </section>
        <section class="sub-section DetailInfo">
            <div class="innWrap scrWrap flex">
                <div class="scrNav">
                    <ul>
                        <li @click="updateTab(1)">
                            <span class="line" v-bind:class="{active: active === 1}"></span><a href="#section-01" v-smooth-scroll v-bind:class="{active: active === 1}">상세정보</a>
                        </li>
                        <li @click="updateTab(2)">
                            <span class="line" v-bind:class="{active: active === 2}"></span><a href="#section-02" v-smooth-scroll v-bind:class="{active: active === 2}">이용방법</a>
                        </li>
                        <li @click="updateTab(3)">
                            <span class="line" v-bind:class="{active: active === 3}"></span><a href="#section-03" v-smooth-scroll v-bind:class="{active: active === 3}">Flexline 이용안내</a>
                        </li>
                    </ul>
                </div>

                <div class="scrCon">
                    <div id="section-01" class="flex-section-01 Sbdb1px">
                        <h3 class="sCtitle">상세정보</h3>
                        <dl class="flex sLayout01 con01">
                            <dd>
                                <div class="imgWrap">
                                    <div class="img"></div>
                                </div>
                            </dd>
                            <dt>
                                <p class="agr">최적의 네트워크 구성이<br />가능한 SD-WAN 솔루션</p>
                            </dt>
                        </dl>
                        <dl class="flex sLayout01 w886 con02">
                            <dt>
                                <p>고객 맞춤형의 유연한 회선 및<br />온라인 서비스 포탈</p>
                            </dt>
                            <dd>
                                <div class="imgWrap">
                                    <div class="img"></div>
                                </div>
                            </dd>
                        </dl>
                        <dl class="flex sLayout01 con03">
                            <dd>
                                <div class="imgWrap">
                                    <div class="img"></div>
                                </div>
                            </dd>
                            <dt>
                                <p class="agr">전담 운용센터의<br />안정적인<br />서비스 관리/관제 제공</p>
                            </dt>
                        </dl>
                        <dl class="flex sLayout01 con04">
                            <dt>
                                <p>다양한  Cloud 사업자와<br />하나의 회선으로 최적 연결</p>
                            </dt>
                            <dd>
                                <div class="imgWrap">
                                    <div class="img"></div>
                                </div>
                            </dd>
                        </dl>
                    </div>

                    <div id="section-02" class="flex-section-02 Sbdb1px">
                        <h3 class="sCtitle">이용방법</h3>
                        <div class="con01">
                            <h4>1. 기본서비스</h4>
                            <div class="sLayouBox">
                                <div class="left">
                                    <div class="boxTit">01. Flexline Basic</div>
                                    <div class="boxCon">
                                        <ul class="dotList">
                                            <p>단일회선으로 인터넷망/업무망을 동시 사용하는<br />50인 미만의 소규모 사업장</p>
                                            <li>
                                                4종의 회선 제공(일반인터넷 기반)
                                                <span>
                                                    최대속도 100M/200/500/1G<br />
                                                    ※ 일반인터넷 최대속도는 설치장소의 통신설비 환경, PC 사양, 단말 등에<br />
                                                    따라 실제 제공속도와 다를 수 있습니다.
                                                </span>
                                            </li>
                                            <li>
                                                공인IP 제공
                                                <span>기본 1개, 최대 2개 추가 기능(부가서비스)</span>
                                            </li>
                                            <li>
                                                50인 규모 소형 장비
                                                <span>SD-WAN  기능 및 방화벽,  IPSec VPN  등 보안 기능 포함</span>
                                            </li>
                                            <li>
                                                Cloud Direct( 부가서비스)
                                                <span>
                                                    최소 1M~ 최대 1G, 6개사 연결 제공<br />
                                                    (AWS, MS Azure, Google, KT Cloud, NHN, Naver)
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="right">
                                    <div class="boxTit">02. Flexline Premium</div>
                                    <div class="boxCon">
                                        <ul class="dotList pink">
                                            <p>업무망의 성능 보장이 필요하며 본/지사 연결이 필요한<br />50인 이상의 중 ·대규모 사업장</p>
                                            <li>
                                                9종의 회선 제공(기업인터넷 기반)
                                                <span>200M ~ 1G (100M 단위)</span>
                                            </li>
                                            <li>
                                                공인IP 제공
                                                <span>기본 8~16개, 최대 256개 추가 기능(부가서비스)</span>
                                            </li>
                                            <li>
                                                100인 규모 대형 장비
                                                <span>SD-WAN  기능 및 방화벽,  IPSec VPN  등 보안 기능 포함</span>
                                            </li>
                                            <li>
                                                Cloud Direct( 부가서비스)
                                                <span>
                                                    최소 1M~ 최대 1G, 6개사 연결 제공<br />
                                                    (AWS, MS Azure, Google, KT Cloud, NHN, Naver)
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div><!-- //con01 -->

                        <div class="con02">
                            <h4>2. 기대효과</h4>
                            <div>
                                이용 패턴에 따른 네트워크 성능 최적화 및 대역폭 변경으로, 귀사의 모든 네트워크를<br />
                                효율적으로 관리하고 변화하는 업무 환경에 유연하게 대응할 수 있습니다.
                            </div>
                        </div><!-- //con02 -->

                        <div class="con03">
                            <h4>3. 추천상품</h4>
                            <p class="pdl30">관련 상품과 함께 이용하면 더 좋습니다.</p>
                            <div class="sLayouIcon">
                                <dl>
                                    <dt>기업인터넷</dt>
                                    <dd>국내 최대 용량의 기업전용망을 통한<br />고품질 인터넷 전용회선 서비스</dd>
                                </dl>
                                <dl>
                                    <dt>전용회선</dt>
                                    <dd>완벽한 보안성능을 갖춘 24시간<br />단독 회선</dd>
                                </dl>
                                <dl>
                                    <dt>일반인터넷</dt>
                                    <dd>특수 사용 목적/기능이 필요 없는<br />고객님을 위한 일반 인터넷</dd>
                                </dl>
                            </div>
                        </div><!-- //con03 -->
                    </div>

                    <div id="section-03" class="flex-section-03">
                        <h3 class="sCtitle">Flexline 이용안내</h3>
                        <h4>꼭 알아두세요!</h4>
                        <div class="dotList indent">
                            <ul>
                                <li>서비스 신청에서 개통까지 3~5일 소요되며, 회선 개통 후 장비 개통이 진행됩니다.</li>
                                <li>설치환경에 따라 개통일정은 변경될 수 있습니다.</li>
                            </ul>
                        </div>
                        <h4>설치 및 이용 가이드</h4>
                        <div class="dotList indent">
                            <ul>
                                <li> Cloud 연결을 위한 Cloud Direct 서비스는 부가서비스로 회선 가입 시 서비스 신청이 가능합니다.</li>
                            </ul>
                        </div>
                        <h4>신청 및 문의</h4>
                        <div class="dotList indent">
                            <ul>
                                <li>전화상담신청(서비스문의, 신청 및 해지) : 1588-0114</li>
                                <li>고장(장애)접수 : 1588-0114</li>
                                <li>1:1 문의하기</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
    <section>
      <footerLayout></footerLayout>
    </section>
    </div>
  </body>
</template>
<script>
import HeaderLayout from './common/Header.vue'
import FooterLayout from './common/Footer.vue'

export default {
  components: {
    HeaderLayout,
    FooterLayout
  },
  data () {
    return {
        active: 1
    }
  },
  created: function () {
  },
  mounted: function () {
  },
  beforeDestroy: function () {
  },
  methods: {
      updateTab(num) {
          this.active = num
      }
  }
}
</script>